<template>
  <div>
    <div class="dropdown v-select vs--single vs--searchable">
      <div class="vs__dropdown-toggle" :id="id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div class="vs__selected-options">
          <span class="vs__selected">
            <div data-v-5742bf08="" class="selected d-center grey-field">
              <span v-for="(selected, index) in value" v-bind:key="index">
                {{ selected.quantity }}x {{ selected[fieldName]}}
                <span v-if="index != Object.keys(value).length - 1">, </span>
              </span>
            </div>
          </span>
          <span  class="vs__search" style="height: 20px">
          </span>
        </div>
        <div class="vs__actions">
          <button type="button" title="Clear Selected" aria-label="Clear Selected" class="vs__clear" style="display: none;">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg></button> <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation" class="vs__open-indicator"><path d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"></path></svg>
          <div class="vs__spinner" style="display: none;">Loading...</div>
        </div>
      </div>
      <div class="dropdown-menu" :aria-labelledby="id">
        <a class="dropdown-item" v-for="(option, index) in getOptions " v-bind:key="index" >
          <div class="row">
            <div class="col-6 item-div">
              <span class="item-text">{{ option[fieldName]  }}</span>
            </div>
            <div class="col-6 button-div">
              <button type="button" class="btn btn-link btn-sm botoes" v-on:click.stop="removeEquipment(option)" :disabled="disabled">
                <i class="ft ft-minus-circle" />
              </button>
              <input  readonly class="sm" v-model="option.quantity">
              <button type="button" class="btn btn-link btn-sm"   v-on:click.stop="addEquipment(option)" :disabled="disabled">
                <i class="ft ft-plus-circle" />
              </button>
            </div>
          </div>
        </a>
      </div>
    </div>
    <transition name="slide" mode="">
      <small v-if="error" class="royalc-error-field">{{ error }}</small>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src

// Services

export default {
  name: 'SearchBarQuantity',
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  data () {
    return {
      id: null
    }
  },
  computed: {
    getOptions () {
      return this.options
    }
  },
  props: {
    error: {
      type: String,
      default: null,
      required: false
    },
    options: {
      type: Array,
      default: (val) => {
        val = []
        return val
      }
    },
    fieldName: {
      type: String,
      required: false,
      default: 'nome'
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // Equipment
    removeEquipment (value) {
      if (value.quantity > 0) {
        value.quantity--

        let _this = this
        let selected = _this.value.findIndex((item) => {
          return item.id === value.id
        })

        if (selected > -1) {
          if (value.quantity < 1) {
            // Remove Item
            _this.value.splice(selected, 1)
          } else {
            // Remove Item
            _this.value.splice(selected, 1)
            // Adiciona novamente
            _this.value.splice(selected, 1, value)
          }
        }
      }
    },
    addEquipment (value) {
      value.quantity++

      let _this = this
      let selected = _this.value.findIndex((item) => {
        return item.id === value.id
      })

      if (selected === -1) {
        _this.value.push(value)
      } else {
        // Remove Item
        _this.value.splice(selected, 1)
        // Adiciona novamente
        _this.value.splice(selected, 1, value)
      }
    },
    onSearch () {
      this.$emit('search', this.value)
    },
    equipmentBeforeSelect (value) {
    },
    applyTranslate (val) {
      // Se não informado retorna vazio
      if (!val) {
        return ''
      }

      let translated = this.$i18n.t('taskCampaign.fields.' + val)

      if (translated.includes('taskCampaign.fields.')) {
        return val
      }

      return translated
    }
  },
  created () {
    this.id = this._uid
  },
  watch: {
    value: function (newValue, oldValue) {
      for (let i in newValue) {
        let value = newValue[i]

        let index = this.options.findIndex((e) => {
          return e.id === value.id
        })

        if (this.options[index].quantity !== value.quantity) {
          this.options[index].quantity = value.quantity
        }
      }
    }
  }
}
</script>

<style scoped>
  .dropdown {
    background-color: white;
  }

  .dropdown-menu {
    width: 100%;
    min-width: 300px !important;
  }

  input.sm {
    width: 30px;
    text-align: center;
  }

  i.ft {
    font-size: 20px;
    padding: 3px;
  }

  .item-text {
    color: gray;
  }

  .item-div {
    margin: auto;
  }

  .buttons-div {
    text-align: right;
    margin: auto;
  }
</style>
